var service = {};
// Local
if (
  window.location.href.startsWith("http://localhost") ||
  window.location.hostname.endsWith(".me.maven.com")
) {
  service.API_URL = "http://localhost:8001/api";
  service.IMG_URL = "http://localhost:8001/";
  service.DOMAIN = ".me.mavenregulatory.com";
} else if (window.location.hostname.endsWith(".mavenprofserv.us")) {
  service.API_URL = "https://api.mavenprofserv.us/api";
  service.IMG_URL = "https://api.mavenprofserv.us/";
  service.DOMAIN = ".mavenprofserv.us";
} else {
  service.API_URL = "https://api.mavenregtech.com/api";
  service.IMG_URL = "https://api.mavenregtech.com/";
  service.DOMAIN = ".mavenregtech.com";
}

export default service;
